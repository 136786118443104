<template>
  <div class="content">
    <el-row class="bgW">
      <el-col :span="22">
        <!--检索条件-->
        <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">
          <el-form-item label="成交日期">
            <el-date-picker
              v-model="value1"
              type="daterange"
              range-separator="至"
              class="w220"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="searchTime"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="客户名称" v-if="!clienId">
            <el-input
              v-model="search.consignee"
              maxlength="30"
              class="w84"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
          <el-form-item label="门店名称" v-if="!clienId">
            <el-input
              v-model="search.shop_name"
              maxlength="30"
              class="w84"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
          <el-form-item label="收款金额">
            <el-input
              v-model="search.low_total"
              maxlength="30"
              class="w84"
              placeholder="最小值"
              clearable
            />
            <span></span>
            <el-input
              v-model="search.large_total"
              maxlength="30"
              class="w84"
              placeholder="最大值"
              clearable
            />
          </el-form-item>
          <el-form-item label="业务类型">
            <el-select v-model="search.business_type" placeholder="请选业务类型" class="w120" clearable>
              <el-option
                v-for="item in options.business_type"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="订单类型">
            <el-select v-model="search.order_type" placeholder="请选订单类型" class="w120" clearable>
              <el-option
                v-for="item in options.order_type"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="作废订单">
            <el-select v-model="search.display_cannel" placeholder="是否显示作废订单" class="w120" clearable>
              <el-option
                v-for="item in options.display_cannel"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2" style="text-align: right">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
      </el-col>
    </el-row>
    <div class="accountCon">
      <div class="btnBox">
        <el-button
          type="primary"
          v-if="!userInfo.shop_id"
          :disabled="!idList.length"
          class="mb10"
          size="mini"
          @click="tapBtn('export')"
        >导出数据</el-button>
        <div v-if="userInfo.shop_id"></div>
        <el-button
          v-if="!clienId"
          type="primary"
          class="mb10"
          size="mini"
          @click="tapBtn('lock')"
        >锁定订单</el-button>
       <span v-if="is_show_add">
          <el-button
            v-if="clienId"
            class="mb10"
            type="primary"
            size="mini"
            round
            @click="tapBtn('新增收款')"
          >新增收款</el-button>
       </span>
     
      </div>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
            @selection-change="handleSelectionChange"
          >
            <ElTableColumn v-if="!userInfo.shop_id" type="selection" width="50" />
            <ElTableColumn label="成交日期" prop="deal_time" width="90" />
            <ElTableColumn label="客户名称" prop="consignee" />
            <ElTableColumn label="客户K3代码" prop="king_no" width="100"/>
            <ElTableColumn label="门店" prop="shop_name" />
            <ElTableColumn label="护理师" prop="sale_name" />
            <ElTableColumn label="收款方式" prop="refund_mode_name" width="130"/>
            <ElTableColumn label="本金" prop="pay_price" />
            <ElTableColumn label="赠金" prop="gift_price" />
            <ElTableColumn label="店转" prop="shop_transfer_price" />
            <ElTableColumn label="收款金额" prop="price_sum" />
            <ElTableColumn label="业务类型">
            <template slot-scope="{ row }">
                <span>{{row.business_type}}</span>
                <span v-if="row.business_two_type != '暂无数据'">----{{row.business_two_type}}</span>
              </template>
            </ElTableColumn>
            <ElTableColumn label="订单类型" prop="order_type" width="70"/>
            <ElTableColumn label="附加类型" prop="extra_type" width="70"/>
            <ElTableColumn label="订单状态" prop="status_msg" />
            <ElTableColumn label="收款类型" prop="refund_type" width="70"/>
            <ElTableColumn label="是否锁定">
              <template slot-scope="{ row }">
                <span>{{row.is_lock == 0?'未锁定':'已锁定'}}</span>
              </template>
            </ElTableColumn>
            <ElTableColumn label="是否支付" prop="is_pay" />
            <ElTableColumn label="支付方式" prop="refund_type_plus" />
            <ElTableColumn label="支付时间" prop="is_pay_time" width="140" />
            <ElTableColumn label="操作" fixed="right" width="160">
              <template slot-scope="{ row }">
                <span class="theme" v-if="clienId"  @click="tapBtn('客户收款单详情',row)">查 看</span>
                <span class="theme" v-else @click="tapBtn('收款单详情',row)">查 看</span>
                 &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                <span class="theme"  @click="printBtn('打印订单',row)">打印</span>
              </template>
            </ElTableColumn>
          </el-table>
          <div class="reveal" v-if="sum_ben_amount != 'noShow'">
            <span class="mr20">
              本金合计：
              <span class="peril">{{sum_ben_amount}}</span>
            </span>
            <span class="mr20">
              赠金合计：
              <span class="peril">{{sum_zeng_amount}}</span>
            </span>
            <span class="mr20">
              收款金额合计：
              <span class="peril">{{sum_ben_amount}}</span>
            </span>
          </div>
          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>
      <!-- <el-image src="http://burn.178590.com/images/qrcode-wx.jpg"></el-image> -->

      <el-dialog title="打印订单" :visible.sync="payQrcodePop" width="12%">
        <!-- printStart -->
        <div id="printBox" style="margin-left: 10%">
            <div>==================</div>
            <div>====={{orderData.order_time}}=====</div>
            <div>==================</div>
            <div>订单编号：</div>
            <div>{{orderData.refund_order_no}}</div>
            <div>==================</div>
            <div>服务中心：</div>
            <div>{{orderData.shop_name}}</div>
            <div>==================</div>
            <div>客户姓名：</div>
            <span>{{orderData.customer_name}}</span>
            <div>==================</div>
            <div>实收金额：</div>
            <span>{{orderData.pay_amount}}</span>
            <div>==================</div>
            <div>====={{orderData.order_time}}=====</div>
            <div>==================</div>
            <div style="width:100%; height:20px;"></div>
            <div>
              <span>公众号二维码：</span>
            </div>
            <div style="width:100%; height:20px;"></div>
            <div id="qrCode" ref="qrCode">
              <el-image :src="orderData.src"></el-image>
            </div>
            <div style="width:100%; height:20px;"></div>
            <div>==================</div>
            <div>====={{orderData.order_time}}=====</div>
            <div>==================</div>
          </div>
        <!-- printEnd -->
        <div style="width:100%; height:20px;"></div>
        <button style="margin-left: 70%" @click="printHtml()">打印</button>
      </el-dialog>
    </div>
  </div>
  
  
</template>

<script>
import { mapGetters } from "vuex";
import pagination from "@/components/Pagination";
import { refundList, refundExport, lockOrderR } from "@/api/order";
import { getPayUrl } from "@/api/pay";
import QRCode from "qrcodejs2";
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Search {
  start_time = "";
  end_time = "";
  consignee = "";
  shop_name = "";
  low_total = "";
  large_total = "";
  business_type = "";
  order_type = "";
  display_cannel = 0;
}
class Options {
  business_type = [
    { id: 1, name: "疤痕" },
    { id: 2, name: "项目" },
	{ id: 3, name: "青春痘" },
	{ id: 4, name: "护肤品" }
  ];
  order_type = [
    { id: 1, name: "新单" },
    { id: 2, name: "补单" }
  ];
  display_cannel = [
    { id: 0, name: "不显示" },
    { id: 1, name: "显示" }
  ];
}
export default {
  name: "OrderList",
  components: {
    pagination
  },
  props: {
    clienId: {
      type: String,
      default: ""
    },
    detail: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      search: new Search(), // 检索条件
      options: new Options(), // 选项
      page: new Page(), // 分页
      list: [], // 数据展示
      idList: "",
      value1: [],
      sum_ben_amount: "noShow",
      sum_zeng_amount: "",
      orderData:{
        refund_order_no:"",
        shop_name:"",
        customer_name:"",
        pay_amount:"",
        order_time:"",
        src:"",
      },
      payData: {
        order_id:"",
        authCode:"",
      }, // 支付信息
      payQrcodePop:false,
      is_show_add:true,
    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  mounted() {
    this.getList();
    console.log(this.clienId)
  },
  methods: {
    // 获取列表
    getList(option) {
      if (option == "search") this.page = new Page();
      refundList({
        ...this.search,
        ...this.page,
        type: 3,
        uid: this.clienId ? this.clienId : "",
        status: "-1"
      }).then(res => {
        this.is_show_add = res.is_show_add
        this.list = res.list;
        this.page.total = res.dataCount;
        if (this.search.end_time) {
          this.sum_ben_amount = res.sum_ben_amount;
          this.sum_zeng_amount = res.sum_zeng_amount;
        } else {
          this.sum_ben_amount = "noShow";
        }
      });
    },
    // 日期搜索
    searchTime(val) {
      if (val) {
        this.search.start_time = val[0];
        this.search.end_time = val[1];
      } else {
        this.search.start_time = "";
        this.search.end_time = "";
      }
    },
    // 点击详情
    tapBtn(type, row) {
      switch (type) {
        case "money":
          this.$router.push("./addRecedeMoney");
          break;
        case "goods":
          this.$router.push("./addRecedeGoods");
          break;
        case "收款单详情":
          this.$router.push(
            "./refundDetail?id=" +
              row.id +
              "&type=" +
              row.type +
              "&gathering=true"
          );
          break;
        case "客户收款单详情":
         
          const query = {
            id: row.id,
            types: "second",
            type: row.type,
            clienId: this.clienId,
            detail: this.detail
          };
          this.$router.push({ name: "退/收单详情", query });
          break;
        case "export":
          this.hint("导出选中列表", "export");
          break;
        case "新增收款":
          const querys = { detail: this.detail };
          this.$router.push({ name: "添加收款订单", query: querys });
          break;
        case "lock":
          if (this.search.end_time) {
            this.hint("锁定选中条件的订单", "lock");
          } else {
            this.$message({
              message: "请选择锁定日期",
              type: "warning"
            });
          }
          break;
      }
    },
    // 提示
    hint(text, type) {
      this.$confirm(`${text},是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          if (type == "export") {
            this.getExport();
          } else {
            this.getLockOrder();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //锁单
    getLockOrder() {
      lockOrderR({ ...this.search, type: 3 }).then(res => {
        this.$message({
          message: res.msg,
          type: "success"
        });
        this.getList();
      });
    },
    // 导出
    getExport() {
      refundExport({ id: this.idList, type: 3 }).then(res => {
        window.open(res.data.url);
      });
    },

    // 改变表格选中状态
    handleSelectionChange(val) {
      this.idList = val.map(i => {
        return i.id;
      });
    },

    // 
    printBtn(type,data){
      this.payData.order_id = data.id;
      this.payData.type = 'WeChat';

      getPayUrl({
        // order_id: date.id
        ...this.payData
      }).then(res=>{
        console.log(res.data);
        this.payQrcodePop = true;
        this.orderData.refund_order_no = res.data.refund_order_no;
        this.orderData.shop_name = res.data.shop_name;
        this.orderData.customer_name = res.data.customer_name;
        this.orderData.pay_amount = res.data.pay_amount;
        this.orderData.order_time = res.data.deal_time;
        this.orderData.src = "http://burn.178590.com/images/qrcode-wx.jpg";
        
        // this.printHtml();
        // this.printHtml();
      });
    },

    //创建二维码
    qrCodeCreate(url) {
      // console.log('生成支付二维码的链接：'+this.$refs.qrCode);

      this.$refs.qrCode.innerHTML = "";

      var qrcode = new QRCode(this.$refs.qrCode, {
        // text: 'xxxx', // 需要转换为二维码的内容
        text: url, // 需要转换为二维码的内容
        width: 150,
        height: 150,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    },

    printHtml() {
        let bodyHtml = window.document.body.innerHTML;
        let printBox = document.getElementById('printBox').innerHTML;
        window.document.body.innerHTML = printBox;
        // console.log('333');
        window.print();
        location.reload();
        // window.document.body.innerHTML = bodyHtml;
    }
  }
};
</script>

<style lang="scss" scoped>
.accountCon {
  background: #fff;
  padding: 10px 0px 80px;
  .btnBox {
    display: flex;
    justify-content: space-between;
  }
}
.reveal {
  text-align: right;
  padding: 20px 0;
  .peril {
    font-weight: bold;
  }
}
</style>
